/* src/Sidebar.css */

.sidebar {
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px;
    height: 100%;
    background-color: #f9fafb;
    transition: left 0.3s ease-in-out;
    z-index: 1030;
    padding-top: 50px;
    overflow-y: auto;
    /* Allow vertical scrolling */
  }
  
  #sidebar-toggle:checked + .sidebar {
    left: 0;
  }
  
  .overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1020;
  }
  
  #sidebar-toggle:checked + .sidebar + .overlay {
    display: block;
  }
  
  #sidebar-toggle {
    display: none;
  }
  
  .sidebar-toggler {
    cursor: pointer;
  }
  
  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  .navbar-nav .nav-item {
    border-bottom: 1px solid #f1f4f6;
    display: flex;
    align-items: center;
    padding: 10px;
    position: relative;
    transition: background-color 0.3s;
  }
  
  .navbar-nav .nav-item .icon {
    margin-right: 10px;
  }
  
  .nav-item:hover {
    background-color: #f85a00;
  }
  
  .nav-item:hover .nav-link {
    width: calc(100% - 20px);
  }
  
  .nav-item ul {
    display: none;
    padding-left: 20px;
  }
  
  .nav-item:hover ul {
    display: block;
  }
  
  .secondary-sidebar {
    position: fixed;
    top: 0;
    left: 250px;
    width: 250px;
    height: 100%;
    background-color: #f9fafb;
    transition: transform 0.3s ease-in-out, visibility 0.3s, opacity 0.3s;
    transform: translateX(-100%);
    z-index: 1040;
    padding-top: 50px;
    visibility: hidden;
    opacity: 0;
  }
  
  .nav-item:hover .secondary-sidebar {
    transform: translateX(0);
    visibility: visible;
    opacity: 1;
  }
  
  .nav-item .arrow {
    position: absolute;
    right: 10px;
    transition: transform 0.3s;
  }
  
  .nav-item:hover .arrow {
    transform: translateX(5px);
  }
  
  .secondary-sidebar .nav-item {
    position: relative;
    border-bottom: none;
    padding: 10px;
    transition: background-color 0.3s;
  }
  
  .secondary-sidebar .nav-item:hover {
    background-color: #f85a00;
  }
  
  .third-sidebar {
    position: fixed;
    top: 0;
    left: 250px;
    width: 250px;
    height: 100%;
    background-color: #f9fafb;
    transition: transform 0.3s ease-in-out, visibility 0.3s, opacity 0.3s;
    transform: translateX(-100%);
    z-index: 1050;
    padding-top: 50px;
    visibility: hidden;
    opacity: 0;
  }
  
  .secondary-sidebar .nav-item:hover .third-sidebar {
    transform: translateX(0);
    visibility: visible;
    opacity: 1;
  }
  
  .third-sidebar .nav-item {
    position: relative;
    border-bottom: none;
    padding: 10px;
    transition: background-color 0.3s;
  }
  
  .third-sidebar .nav-item:hover {
    background-color: #f85a00;
  }
  
  .navbar-nav .nav-item .float {
    position: absolute;
    right: 10px;
  }
  