.image-gallery-wrapper {
  width: 100%;
  overflow: hidden;
}

.no-drag .image-gallery-image {
  pointer-events: none;
  user-select: none;
  -webkit-user-drag: none;
}

.image-gallery-slide {
  cursor: grab;
}

.image-gallery-slide:active {
  cursor: grabbing;
} 