.payment-failure-container {
    min-height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f9fa;
    padding: 20px;
}

.failure-card {
    background: white;
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 500px;
    width: 100%;
    animation: slideIn 0.5s ease-out;
}

@keyframes slideIn {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.icon-wrapper {
    background: #fff0f0;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 20px;
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(244, 67, 54, 0.4);
    }
    70% {
        transform: scale(1.05);
        box-shadow: 0 0 0 10px rgba(244, 67, 54, 0);
    }
    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(244, 67, 54, 0);
    }
}

.failure-icon {
    color: #f44336;
    font-size: 48px;
}

.failure-title {
    color: #333;
    font-size: 28px;
    margin-bottom: 15px;
}
.failure-order-number{
    color: #333;
    font-size: 14px;
    line-height :0;
    font-weight: 500;
    margin-bottom: 15px;
    font-style: italic;
    
}
.failure-message {
    color: #666;
    margin-bottom: 30px;
    line-height: 1.6;
}

.button-group {
    display: flex;
    gap: 15px;
    justify-content: center;
    flex-wrap: wrap;
}

.retry-button, .home-button {
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    display: flex;
    align-items: center;
    gap: 8px;
}

.retry-button {
    background-color: #f44336;
    color: white;
}

.home-button {
    background-color: #2196F3;
    color: white;
}

.retry-button:hover, .home-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.retry-button:active, .home-button:active {
    transform: translateY(0);
}

@media (max-width: 480px) {
    .failure-card {
        padding: 20px;
    }
    
    .button-group {
        flex-direction: column;
    }
    
    .retry-button, .home-button {
        width: 100%;
    }
}